<template>
  <b-card :title="course.curriculum.title" v-if="course.curriculum">
    <div
        v-for="lesson in course.curriculum.lessons"
        :key="lesson.id"
        :item="lesson"
        class="pt-0 pb-0 d-flex flex-row bd-highlight mb-0 lesson-item"
        @click="showLesson"
        style="margin-bottom: 5px !important;"
    >
      <div style="margin-right: 7px;">
        <feather-icon icon="FlagIcon" size="18"/>
      </div>
      <b-link
          class="d-flex align-content-start py-0 px-0 flex-column"
          style="margin-bottom: 7px;"
      >
                <span class="menu-title text-wrap font-medium-1"
                      v-bind:style="{ color: lesson.color ? lesson.color : '#625f6e', fontWeight: lesson.bold ? 'bold' : 'normal'}">
                  {{ lesson.title }}
                </span>
      </b-link>
    </div>
  </b-card>
</template>
<script>
import {BButton, BCard, BCardBody, BCardText, BLink, BListGroup, BListGroupItem, BModal} from "bootstrap-vue";

export default {
  components: {
    BLink,
    BListGroupItem, BListGroup,
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BModal,
  },
  name: 'CurriculumPanel',
  props: {
    course: {},
    showLesson: {}
  }
}
</script>
<style>
img, iframe {
  max-width: 100%;
  height: auto;
}

</style>
<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
// prevent copying of text
/*.lesson-content {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}*/
/* Because of the SVG inliner, these don't work for icons */

@keyframes atom {
  from {
    transform: none;
  }
  to {
    transform: translateY(-10px);
  }
}

@keyframes electron-circle1 {
  from {
    transform: rotateY(70deg) rotateZ(20deg);
  }
  to {
    transform: rotateY(70deg) rotateZ(380deg);
  }
}

@keyframes electron1 {
  from {
    transform: rotateZ(-20deg) rotateY(-70deg);
  }
  to {
    transform: rotateZ(-380deg) rotateY(-70deg);
  }
}

@keyframes electron-circle2 {
  from {
    transform: rotateY(60deg) rotateX(60deg) rotateZ(-30deg);
  }
  to {
    transform: rotateY(60deg) rotateX(60deg) rotateZ(330deg);
  }
}

@keyframes electron2 {
  from {
    transform: rotateZ(30deg) rotateX(-60deg) rotateY(-60deg);
  }
  to {
    transform: rotateZ(-330deg) rotateX(-60deg) rotateY(-60deg);
  }
}

@keyframes electron-circle3 {
  from {
    transform: rotateY(-60deg) rotateX(60deg) rotateZ(100deg);
  }
  to {
    transform: rotateY(-60deg) rotateX(60deg) rotateZ(460deg);
  }
}

@keyframes electron3 {
  from {
    transform: rotateZ(-100deg) rotateX(-60deg) rotateY(60deg);
  }
  to {
    transform: rotateZ(-460deg) rotateX(-60deg) rotateY(60deg);
  }
}

</style>