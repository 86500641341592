<template>
  <div>
    <div class="quotation" :style="{ backgroundImage: `url(${quoatationImg})` }">
      <p><strong>{{ quotation.cytat }}</strong></p>
      <p class="font-italic text-right">{{ quotation.autor }}</p>
    </div>
    <div class="quotation-mobile" :style="{ backgroundImage: `url(${quoatationMobileImg})` }">
      <p><strong>{{ quotation.cytat }}</strong></p>
      <p class="font-italic text-right">{{ quotation.autor }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Quotation',
  props: {
    quoatationImg: {},
    quoatationMobileImg: {},
    quotation: {}
  }
}
</script>
<style lang="scss">
.quotation, .quotation-mobile {
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  padding-left: 330px;
  padding-right: 30px;
  padding-top: 100px;
  margin-bottom: 30px;
  font-size: 16px;
  p{
    strong{
      font-size: 22px;
    }
  }
}

@media screen and (max-width: 992px) {
  .quotation {
    display: none;
  }
  .quotation-mobile {
    display: block;
    padding-left: 150px;
    padding-right: 10px;
    font-size: 12px;
    padding-top: 80px;
    min-height: 220px;
    aspect-ratio: 1.5 / 1;
    p{
      strong{
        font-size: 15px;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .quotation {
    display: block;
    aspect-ratio: 2.66 / 1;
  }
  .quotation-mobile {
    display: none;
  }
}
@media screen and (min-width: 1600px) {
  .quotation, .quotation-mobile {
    padding-left: 400px;
    padding-top: 130px;
  }
}
h4 {
  color: #20ad95;
}
</style>